"use client";
import React, { useEffect } from "react";

function Preline() {
  useEffect(() => {
    import("preline");
  }, []);
  return <></>;
}

export default Preline;
