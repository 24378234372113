"use client";
import React, { useEffect, useState } from "react";

function CookieBanner() {
  const [isCookieConsentGiven, setisCookieConsentGiven] = useState(true);
  const acceptCookies = () => {
    // Set localStorage indicating consent
    localStorage.setItem("cookieConsent", "true");
  };

  useEffect(() => {
    const cookieConsent = localStorage.getItem("cookieConsent");
    if (!cookieConsent) {
      setisCookieConsentGiven(!!cookieConsent);
    }
  }, [isCookieConsentGiven]);

  if (!isCookieConsentGiven) {
    return (
      <>
        <div
          id="cookies-simple-with-dismiss-button"
          className="fixed bottom-0 m-auto w-full flex justify-center z-[60] mx-auto p-6"
        >
          {/* Card */}
          <div className="p-4 bg-purple-100 border max-w-4xl border-gray-200 rounded-xl shadow-lg dark:bg-gray-800 dark:border-gray-700">
            <div className="flex justify-between items-center gap-x-5 sm:gap-x-10">
              <h2 className="text-sm text-gray-600 dark:text-gray-400">
                By continuing to use this site you consent to the use of cookies
                in accordance with our{" "}
                <a
                  className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium"
                  href="/policies/cookies"
                >
                  Cookies Policy.
                </a>
              </h2>
              <button
                type="button"
                onClick={acceptCookies}
                className="p-2 inline-flex items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent bg-purple-100 text-purple-800 hover:bg-purple-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-white/10 dark:hover:bg-white/20 dark:text-white dark:hover:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                data-hs-remove-element="#cookies-simple-with-dismiss-button"
              >
                <span className="sr-only">Dismiss</span>
                <svg
                  className="flex-shrink-0 size-5"
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 6 6 18" />
                  <path d="m6 6 12 12" />
                </svg>
              </button>
            </div>
          </div>
          {/* End Card */}
        </div>
      </>
    );
  }

  return <></>;
}

export default CookieBanner;
